<template>
    <div class="image-block" :class="{ 'portrait': isPortrait }">
        <div :class="[{ 'allow-zoom': block.value.allow_zoom }, 'img-wrap']">
            <img :src="block.value.image.medium" :alt="block.value.image.alt" @click="handleImageClick(block)"
                 />
            <IconExpand v-if="block.value.allow_zoom" class="expand-icon" />
        </div>
        <span v-if="block.value.image.caption" class="caption">{{ block.value.image.caption }}</span>
    </div>
</template>

<script>
import IconExpand from "@/assets/svg/icon-expand.svg"

export default {
    name: 'ZoomImage',
    components: {
        IconExpand,
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        isPortrait() {
            return (this.block.value.image.width < this.block.value.image.height) && (this.block.value.image.height > 699);
        }
    },
    methods: {
        handleImageClick(block) {
            if (block.value.allow_zoom) {
                window.open(block.value.image.file, '_blank');
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
.image-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.img-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    position: relative;
}

.img-wrap.allow-zoom {
    cursor: pointer;
}

.img-wrap .expand-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transition: opacity 0.3s;
}

.img-wrap:hover .expand-icon {
    opacity: 1;
}

.img-wrap img {
    width: 100%;
}

.portrait {
    width: 50%;
}

.caption {
    font-size: 14px
}
</style>
