import Vue from 'vue'

export const state = () => ({
    id_map: {},

    article: null,
    article_error: null,
    article_loading: false,

    articles: null,
    articles_page: null,
    articles_error: null,
    articles_loading: false
})

export const getters = {
    article: state => state.article,
    article_error: state => state.article_error,
    article_loading: state => state.article_loading,

    articles: state => state.articles,
    articles_page: state => state.articles_page,
    articles_error: state => state.articles_error,
    articles_loading: state => state.articles_loading
};

export const actions = {
    /**
     * Retrive an article detail page for Digtial Augustan Rome
     * @param {*} param0
     * @param {*} slug
     */
    fetch_list({commit, state}) {
        if(state.articles) {
            return
        }

        commit('setArticlesLoading', true)

        let aid = null;
        let reqPage = new XMLHttpRequest();
        reqPage.open('GET', `${window.location.origin}/api/pages/?slug=articles`);
        reqPage.responseType = 'json';
        reqPage.send();
        reqPage.onload = () => {
            if(reqPage.status == 200) {
                try {
                    if(!reqPage.response.items[0].id) {
                        commit('setArticlesError', `Articles error, cannot unwap page ID`)
                        return
                    }
                } catch(e) {
                    commit('setArticlesError', `Articles error: ${e.statusText}`)
                    return
                }

                let reqList= new XMLHttpRequest()
                reqList.open('GET', `${window.location.origin}/api/pages/?child_of=${reqPage.response.items[0].id}&type=generic.TextPage&fields=authors(*)`)
                reqList.responseType = 'json'
                reqList.send()
                reqList.onload = () => {
                    if(reqList.status == 200) {
                        commit('setArticles', reqList.response.items)
                    } else{
                        commit('setArticlesError', `Articles error ${reqList.status} : ${reqList.statusText}`)
                    }
                }
                reqList.onerror = () => {
                    commit('setArticlesError', `Articles request failed.`)
                }
            } else{
                commit('setArticlesError', `Articles error ${reqPage.status} : ${reqPage.statusText}`)
            }
        }
        reqPage.onerror = () => {
            commit('setArticlesError', `Articles request failed.`)
        }
    },

    /**
     * Retrieve a list of all Articles for the Digital Augustan Rome.
     * @param {*} param0
     * @param {*} slug
     */
    fetch({commit, state}, slug) {
        if(state.authors) {
            return
        }

        commit('setArticleLoading', true)

        let req = new XMLHttpRequest()
        console.log(`${window.location.origin}/api/pages/${state.id_map[slug]}/?fields=authors(*)`)
        req.open('GET', `${window.location.origin}/api/pages/${state.id_map[slug]}/?fields=authors(*)`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status == 200) {
                commit('setArticle', req.response)
            } else{
                commit('setArticleError', `Article error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setArticleError', `Article request failed.`)
        }
    },

    fetch_page({commit, state}) {
        if(state.articles) {
            return
        }

        commit('setArticlesLoading', true)

        let aid = null
        let reqPage = new XMLHttpRequest()
        reqPage.open('GET', `${window.location.origin}/api/pages/?slug=articles&type=dag.DigitalAugustanRomeStub&fields=preview_thumbnail_image`)
        reqPage.responseType = 'json'
        reqPage.send()
        reqPage.onload = () => {
            if(reqPage.status == 200) {
                try {
                    if(!reqPage.response.items[0].id) {
                        commit('setArticlesPageError', `Articles page error, cannot unwap page ID`)
                        return
                    } else {
                        commit('setArticlesPage', reqPage.response)
                    }
                } catch(e) {
                    commit('setArticlesPageError', `Articles page error: ${e.statusText}`)
                    return
                }
        
            } else {
                reqPage.onerror = () => {
                    commit('setArticlesPageError', `Articles request failed.`)
                }
            }
        }
    }
};

export const mutations = {
    setArticle(state, response) {
        state.article = response
        state.article_loading = false
    },
    setArticleError(state, e) {
        state.article_error = e
        state.article_loading = false
        throw new Error(`Error fetching article: ${e}`)
    },
    setArticleLoading(state, s) {
        state.article_loading = s
    },
    setArticles(state, response) {
        for(var i=0; i < response.length; i++) {
            state.id_map[response[i].meta.slug] = response[i].id
        }
        state.articles = response
        state.articles_loading = false
    },
    setArticlesError(state, e) {
        state.articles_error = e
        state.articles_loading = false
        throw new Error(`Error fetching articles: ${e}`)
    },
    setArticlesPage(state, response) {
        state.articles_page = response
        state.articles_loading = false
    },
    setArticlesPageError(state, e) {
        state.articles_error = e
        state.articles_loading = false
        throw new Error(`Error fetching articles page: ${e}`)
    },
    setArticlesLoading(state, s) {
        state.articles_loading = s
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
